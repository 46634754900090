import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./Components/Loader/Loader.jsx";
import "@fontsource-variable/rubik";
import "@fontsource-variable/inter";
import "swiper/swiper-bundle.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const ScrollToTop = lazy(() => import("./Components/ScrollToTop"));
const ClientLayout = lazy(() => import("./Layouts/ClientLayout"));
const DashboardLayout = lazy(() => import("./Layouts/DashboardLayout"));
const Home = lazy(() => import("./Pages/Homepage.jsx"));
const AboutPage = lazy(() => import("./Pages/AboutPage.jsx"));
const CoursesPage = lazy(() => import("./Pages/CoursesPage.jsx"));
const ContactPage = lazy(() => import("./Pages/ContactPage.jsx"));
const EnrollPage = lazy(() => import("./Pages/EnrollPage.jsx"));
const ConfirmEmail = lazy(() => import("./Pages/ConfirmEmail.jsx"));
const LoginPage = lazy(() => import("./Pages/LoginPage.jsx"));
const StdDashboard = lazy(() => import("./Pages/StdDashboard.jsx"));
const ScheduledClasses = lazy(() => import("./Pages/ScheduledClasses.jsx"));
const ClassHistory = lazy(() => import("./Pages/ClassHistory.jsx"));
const Settings = lazy(() => import("./Pages/Settings.jsx"));
const NotFound = lazy(() => import("./Pages/NotFound"));

const App = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <ScrollToTop>
          <Routes>
            <Route path="*" Component={NotFound} />
            <Route path="/" Component={ClientLayout}>
              <Route index Component={Home} />
              <Route path="about" Component={AboutPage} />
              <Route path="courses" Component={CoursesPage} />
              <Route path="contact" Component={ContactPage} />
              <Route path="enrollment" Component={EnrollPage} />
            </Route>
            <Route path="login" Component={LoginPage} />
            <Route path="confirm-email/:id" Component={ConfirmEmail} />
            <Route path="dashboard" Component={DashboardLayout}>
              <Route index path="home" Component={StdDashboard} />
              <Route path="scheduled-classes" Component={ScheduledClasses} />
              <Route path="class-history" Component={ClassHistory} />
              <Route path="settings" Component={Settings} />
            </Route>
          </Routes>
        </ScrollToTop>
      </Suspense>
      <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </>
  );
};

export default App;
